.home {
  margin: 15px;
  min-height: 100%;
  padding: 20px;
  width: 100%;
  overflow-y: auto;
  h1 {
    margin-top:-15px
  }
}
.right_area {
  height:100%;
  float:right;
  top:25%;
  right:0;
  position:absolute;
  width:27.5%;
  margin:10px;
}

@media screen and (max-width: 680px) {
  .home {
    h1 {
      width: 82%;
      font-size: 1.4em;
    }
  }
}
